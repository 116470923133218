import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import to from 'await-to-js';
import { saveAs } from 'file-saver';
import { AxiosResponse, AxiosError } from 'axios';
import { firebaseAxiosInstance } from '@/axios';
import Loader from '@/components/common/loader/Loader.vue';

@Component({
  components: {
    Loader,
  },
})

export default class AccountDividends extends Vue {
  @Prop({ })
  investmentsPromise!: Promise<any>;

  @Getter getTotalDividendsPerMonth!: number;

  @Getter getSharesTotalInvested!: number;
  @Getter getTotalInvested!: number;
  @Getter getTotalDividends!: number;

  @Action(addToastMessage) addToastMessage!: Function;

  isGeneratingPDF = false;
  previousYear: number = new Date().getFullYear() - 1;
  selectedYear: number = this.previousYear;

   // Determines the number of years shown in the tax statement year selector
  yearsToShow: number = 2;

  /**
   * Get the list of years for which the tax statement can be downloaded
   */
  get years(): number[] {
    return Array.from({ length: this.yearsToShow }, (v, i): number => this.previousYear - i);
  }

  get hasInvestments(): boolean {
    return this.getTotalInvested > 0;
  }

  async downloadStatement(): Promise<void> {
    this.isGeneratingPDF = true;
    const instance = await firebaseAxiosInstance();
    const [err, res] = await to<AxiosResponse, AxiosError>(instance.get('generateDividendsPDF', {
      params: {
        lang: this.$i18n.locale,
        year: this.selectedYear,
      },
      responseType: 'blob',
    }));

    this.isGeneratingPDF = false;

    if (err) {
      let errorMessage;

      if (err.response?.status === 404) {
        errorMessage = this.$t('dashboard.errors.noInvestmentsError');
      } else {
        errorMessage = this.$t('dashboard.errors.pdfError');
      }

      this.addToastMessage({
        text: errorMessage,
        type: 'danger',
      });
    } else {
      saveAs(res!.data, 'statement.pdf');
    }
  }
}
