import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Loader from '@/components/common/loader/Loader.vue';

@Component({
  components: {
    Loader,
  },
})

export default class AccountValue extends Vue {
  @Prop({ })
  investmentsPromise!: Promise<any>;

  period: 'yearly' | 'monthly' = 'monthly';

  @Getter getTotalDividendsPerYear!: number;
  @Getter getTotalDividendsPerMonth!: number;
  @Getter getTotalValue!: number;
}
