import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { capitalize } from '@/filters/string';
import { titleTemplate } from '@/helpers/meta/title-template';

@Component
export default class AccountSettings extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.settings.title').toString()),
    };
  }

  get tabs(): { title: string, routeName: string, icon: string[] }[] {
    return [
      { title: capitalize(this.$t('common.details').toString()), routeName: 'settings-details', icon: ['fa', 'user'] },
      { title: capitalize(this.$t('settings.identification.identification').toString()), routeName: 'settings-identification', icon: ['fa', 'id-card-alt'] },
    ];
  }

  /**
   * Return if tab is active.
   */
  isActiveTab(tabRouteName: string): boolean {
    return (this.$route.name || '').startsWith(tabRouteName);
  }
}
