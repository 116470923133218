import { render, staticRenderFns } from "./AccountValue.html?vue&type=template&id=9a9a6768&scoped=true&"
import script from "./AccountValue.ts?vue&type=script&lang=ts&"
export * from "./AccountValue.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/account/_value.scss?vue&type=style&index=0&id=9a9a6768&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a9a6768",
  null
  
)

export default component.exports