import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { State as StateClass } from '@/store/models';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import FundDocs from '@/components/common/fund-docs/FundDocs.vue';
import ProgressBarDash from '@/components/common/progress-bar-dash/ProgressBarDash.vue';
import AccountInvestments from '@/components/account/investments/AccountInvestments.vue';
import { collections } from '@/vue';
import { db } from '@/firebase';
import { Asset } from '@/store/models/asset';
import { Investment } from '@/store/models/investment';

@Component({
  components: {
    WithDownloads,
    FundDocs,
    ProgressBarDash,
    AccountInvestments,
  },
})

export default class AccountSingleFund extends Vue {
  @Prop({ default: (): undefined => undefined, required: true }) asset!: Asset;
  @Prop({ default: (): undefined => undefined }) investment!: Investment;

  @State('state')
  payments!: StateClass['payments'];

  @Getter getCombinedDividendsFormatByAsset!: Function;
  @Getter hasDifferentPaymentFormatsByAsset!: Function;
  @Getter getInvestmentByAsset!: Function;
  @Getter investmentHasPaidPayments!: Function;
  @Getter getTotalDividendsByAsset!: Function;

  collections = collections;
  promisedAssetsCollection = collections.assets;
  showFundDocs = false;
  handleSupport = false;
  showAllDividends = false;
  showHistory = false;
  historyView: 'investments' | 'dividends' = 'investments';
  dividendsPromise: Promise<any> = Promise.resolve();

  @Watch('asset', { immediate: true })
  onAssetChange(newAsset: Asset): void {
    // Currently querying all the dividends. Later on pagination needs to be added
    this.dividendsPromise = db.collection('assets').doc(newAsset.id).collection('dividends')
      .orderBy('period', 'desc')
      .get();
  }

  get getTotalShares(): number {
    return this.investment?.boughtSharesTotal || 0;
  }

  get hasPaidInvestments(): boolean {
    return this.investment?.id && this.investmentHasPaidPayments(this.investment.id);
  }

  changeView(view: 'investments' | 'dividends'): void {
    this.historyView = view;
  }
}
