import { Vue, Component, Watch } from 'vue-property-decorator';
import { State as StateClass, Getter } from 'vuex-class';
import { MetaInfo } from 'vue-meta';
import { State } from '@/store/models';
import AccountUpgrade from '@/components/account/infobox/AccountUpgrade.vue';
import AccountBalance from '@/components/account/balance/AccountBalance.vue';
import AccountValue from '@/components/account/value/AccountValue.vue';
import AccountDividends from '@/components/account/dividends/AccountDividends.vue';
import AccountFunds from '@/components/account/funds/AccountFunds.vue';
import PropertiesGridList from '@/components/properties/grid-list/PropertiesGridList.vue';
import { premiumAssetTemplate } from '@/helpers/premium-assets/template';
import AccountInvestments from '@/components/account/investments/AccountInvestments.vue';
import Support from '@/components/common/support/Support.vue';
import { metaTitles, metaDescriptions, metaURL, metaLocale, linkAlternate } from '@/helpers/meta';
import { titleTemplate } from '@/helpers/meta/title-template';
import { collections } from '@/vue';
import { Asset } from '@/store/models/asset';
import { Investor } from '@/store/models/user';
import { intercom } from '../../../../whitelabel.config';

// Function that sorts first by shares available (if > 0 has prio), then by other rules
const sortWithAvaliableShares = (assets: Asset[], extraSorting?: (a: Asset, b: Asset) => number): Asset[] =>
  assets.sort((a, b): number => {
    if (a.sharesAvailable && !b.sharesAvailable) {
      return -1;
    }

    if (!a.sharesAvailable && b.sharesAvailable) {
      return 1;
    }

    if (!extraSorting) {
      return 0;
    }

    return extraSorting(a, b);
  });

@Component({
  components: {
    AccountUpgrade,
    AccountBalance,
    AccountValue,
    AccountDividends,
    AccountFunds,
    PropertiesGridList,
    AccountInvestments,
    Support,
  },
})
export default class AccountDashboard extends Vue {
  metaInfo(): MetaInfo {
    const { path } = this.$route;
    const { locale, availableLocales } = this.$i18n;
    return {
      title: titleTemplate(this.$t('meta.dashboard.title').toString()),
      link: [
        ...linkAlternate(path, availableLocales),
      ],
      meta: [
        ...metaTitles(this.$t('meta.properties.title').toString()),
        ...metaDescriptions(this.$t('meta.properties.description').toString()),
        ...metaURL(path),
        ...metaLocale(locale, availableLocales),
      ],
    };
  }

  // Biding collection to the instance
  collections = collections;

  intercom = intercom;

  promisedAssetsCollection = collections.assets;

  handleSupport = false;

  orderType: 'date' | 'search' = 'date';
  assetsOrder: 'DESC' | 'ASC' = 'DESC';
  searchText = '';
  filteredAssets: Asset[] = [];
  maxAssetsPerPage = 3;
  paginationPosition = 0;

  @StateClass user!: State['user'];
  @StateClass idin!: State['idin'];
  @StateClass assets!: State['assets'];
  @StateClass misc!: State['misc'];
  @StateClass identificationRequest!: State['identificationRequest'];

  @Getter getInvestedAssets!: Asset[];
  @Getter getNotInvestedAssets!: Asset[];
  @Getter isInvestor!: boolean;

  @Watch('user')
  onInvestorChange(newUser: Investor, oldUser: Investor): void {
    if (!oldUser && newUser) {
      this.upgradeInfoboxHandle();
    }
  }

  @Watch('isInvestor')
  onIdentifiedChange(): void {
    this.upgradeInfoboxHandle();
  }

  get identificationStatus(): string {
    if (this.isInvestor) {
      return 'approved';
    }

    const customIdent = this.identificationRequest;
    if (customIdent) {
      if (customIdent.status === 'initial') {
        return 'pending';
      }
      if (customIdent.status === 'rejected') {
        return 'rejected';
      }
    }

    return 'initial';
  }

  get showInfoBox(): boolean {
    return this.identificationStatus === 'rejected' || this.identificationStatus === 'pending';
  }

  get notFullNotInvestedAssets(): Asset[] {
    return this.getNotInvestedAssets.filter((asset): boolean => !!asset.sharesAvailable);
  }

  get investedAssets(): Asset[] {
    return this.getInvestedAssets;
  }

  upgradeInfoboxHandle(): void {
    const showUpgradeInfobox = localStorage.getItem('showUpgradeInfoBox');

    if (!!this.user && !this.isInvestor) {
      if (showUpgradeInfobox && JSON.parse(showUpgradeInfobox) === false) {
        this.$store.dispatch('hideUpgradeInfoBox');
      } else {
        this.$store.dispatch('showUpgradeInfoBox');
      }
    }
  }

  @Watch('assets', { immediate: true })
    onAssetsChange(newAssets: Asset[]): void {
      // Resetting filteredAssets
      this.filteredAssets = sortWithAvaliableShares([...newAssets]);

      if (!this.userLoggedIn) {
        this.filteredAssets = this.filteredAssets.map((asset): Asset => ({
          ...asset,
          // The id field is non-enumerable so we need to copy it explicitly since the spread operator won't copy it
          id: asset.id,
          // Use fake data for premium assets
          ...(asset.premium && premiumAssetTemplate(this.$i18n.locale)),
        }));
      }
    }

    mounted(): void {
      this.upgradeInfoboxHandle();
      this.$watch(
        // Watching all those data inputs so we can do the same functionality in one same place
        // @ts-ignore
        (vm): any => [vm.orderType, vm.assetsOrder, vm.searchText].join(),
        (): void => {
          switch (this.orderType) {
            case 'date': {
              this.filteredAssets = sortWithAvaliableShares(
                this.filteredAssets,
                (a, b): number => this.assetsOrder === 'ASC' ? a.createdDateTime.seconds - b.createdDateTime.seconds : b.createdDateTime.seconds - a.createdDateTime.seconds,
              );
              break;
            }
            case 'search': {
              if (!this.searchText) {
                this.filteredAssets = [...this.assets];
              } else {
                const loweredCaseSearchText = this.searchText.toLocaleLowerCase();
                this.filteredAssets = [
                  ...this.assets.filter((asset): boolean => [asset.name, asset.city, (this.$options.filters as any).transformDate(asset.startDateTime)]
                    .map((field): string => field.toLocaleLowerCase())
                    .some((field): boolean => field.includes(loweredCaseSearchText)))];
              }

              // Descending order by default, so we order only when ascending is selected
              if (this.assetsOrder === 'ASC') {
                this.orderType = 'date';
              }
              break;
            }
            default:
              break;
          }
        },
      );
    }

    get paginatedAssets(): Asset[] {
      return this.paginate(this.filteredAssets);
    }

    get totalPages(): number {
      return Math.ceil(this.filteredAssets.length / this.maxAssetsPerPage);
    }

    handlePagination(page: number): void {
      this.paginationPosition = (page - 1) * this.maxAssetsPerPage;
    }

    paginate(assetsArray: Asset[]): Asset[] {
      return assetsArray.slice(this.paginationPosition, this.paginationPosition + this.maxAssetsPerPage);
    }

    handleOrder(assetsOrder: 'DESC' | 'ASC'): void {
      this.assetsOrder = assetsOrder;
      this.orderType = 'date';
    }

    handleSearch(searchText: ''): void {
      this.searchText = searchText;
      this.orderType = 'search';
    }

    get userLoggedIn(): boolean {
      return !!this.user && !!this.user.id;
    }
}
